









































































































import Vue from 'vue'
import { KitchenLightInDB } from '@/api-client/model/kitchen-light-in-db'
import { Route } from 'vue-router'
import { AuthenticationApiFactory } from '@/api-client/api/authentication-api'
import { IS_PROD_BUILD } from '@/utils/globals'

const authAPI = AuthenticationApiFactory(undefined, '')

export default Vue.extend({
  data: () => ({
    loading: false,
    firebaseToken: null as string | null,
    expiration: new Date(),
    zoomValue: 100,
  }),
  computed: {
    isProductionBuild(): boolean {
      return IS_PROD_BUILD
    },
    lastSelectedKitchenID(): string | null {
      return this.$accessor.settings.lastSelectedKitchen
    },
    lastSelectedKitchen(): KitchenLightInDB | null {
      if (!this.lastSelectedKitchenID) return null
      return this.$accessor.cache.kitchen(this.lastSelectedKitchenID)
    },
    lastSelectedKitchenName(): string | null {
      return this.lastSelectedKitchen?.name ?? null
    },
    timerDurationSeconds(): number {
      return this.$accessor.settings.kdsDisplayTimeSeconds
    },
    timerDurationMinutes: {
      get(): number {
        return this.timerDurationSeconds / 60
      },
      set(newValue: number) {
        if (!this.lastSelectedKitchenID) return
        this.$accessor.settings.setKdsDisplayTimeSeconds(
          Math.round(newValue * 60)
        )
      },
    },
    optionsAreValid(): boolean {
      return !!this.lastSelectedKitchenName && this.timerDurationSeconds > 0
    },
    generatedRoute(): Route | null {
      if (
        !(
          this.firebaseToken &&
          this.lastSelectedKitchenID &&
          this.lastSelectedKitchenName
        )
      )
        return null
      const location = {
        name: 'KDS-Display',
        query: {
          id: this.lastSelectedKitchenID,
          name: this.lastSelectedKitchenName,
          timer: String(this.timerDurationSeconds),
          zoom:
            this.zoomValue !== 100 && this.zoomValue > 0
              ? String(this.zoomValue)
              : undefined,
        },
        hash: `#${this.firebaseToken}`,
      }
      const { route } = this.$router.resolve(location)
      return route
    },
    generatedFullURLDeepLink(): string | null {
      const generatedRoute = this.generatedRoute
      if (!generatedRoute) return null
      const { route } = this.$router.resolve({
        ...generatedRoute,
        name: undefined,
        query: {
          ...generatedRoute.query,
          devorCastOrigin: window.location.origin,
        },
      })
      return 'devor-kds-cast://kds' + route.fullPath
    },
    // generatedFullURL is used for debug purposes on non production builds
    generatedFullURL(): string | null {
      if (!this.generatedRoute) return null
      return window.location.origin + this.generatedRoute.fullPath
    },
  },
  created() {
    this.$accessor.cache.updateKitchens()
  },
  methods: {
    async generateURL() {
      if (this.firebaseToken && new Date() < this.expiration) return

      try {
        const response = await authAPI.getFirebaseTokenAuthFirebaseAuthGet()
        this.firebaseToken = response.data.firebase_token ?? null
        const newExpiration = new Date()
        newExpiration.setMinutes(newExpiration.getMinutes() + 45)
        this.expiration = newExpiration
      } catch (error) {
        this.$sentry.captureException(error)
        this.firebaseToken = null
        this.expiration = new Date()
        return
      }
    },
    async openCastApp() {
      this.loading = true

      await this.generateURL()
      if (this.generatedFullURLDeepLink)
        window.location.replace(this.generatedFullURLDeepLink)
      this.loading = false
    },
  },
})
