











































































































import Vue, { PropType } from 'vue'
import Zondicon from 'vue-zondicons'
import { DevorOrderPreparationState } from '@/api-client/model/devor-order-preparation-state'
import { DevorOrderAcceptationState } from '@/api-client/model/devor-order-acceptation-state'
import { sendToPrinter } from '@/utils/epos-printing'
import { DisplayedDevorOrder } from '@/interfaces/display'
import { OrdersApiFactory } from '@/api-client'

const ordersAPI = OrdersApiFactory(undefined, '')

const updatePreparationState = (
  orderID: string,
  kitchenID: string,
  newPreparationState: DevorOrderPreparationState
) =>
  ordersAPI.updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
    orderID,
    kitchenID,
    newPreparationState
  )

export default Vue.extend({
  name: 'ActionPanel',
  components: { Zondicon },
  props: {
    selectedOrder: {
      type: Object as PropType<null | DisplayedDevorOrder>,
      default: null,
    },
  },
  data: () => ({
    printButtonPressed: false,
    showAdditionalMenu: false,
  }),
  computed: {
    isSelectedOrderInKitchen(): boolean {
      if (!this.selectedOrder) {
        return false
      }
      return (
        this.selectedOrder.current_state.acceptation !=
          DevorOrderAcceptationState.Canceled &&
        this.selectedOrder.current_state.preparation ==
          DevorOrderPreparationState.InKitchen
      )
    },
    isSelectedOrderCanceledNotAck(): boolean {
      if (!this.selectedOrder) {
        return false
      }
      return (
        this.selectedOrder.current_state.acceptation ==
          DevorOrderAcceptationState.Canceled &&
        this.selectedOrder.acknowledged_at == null
      )
    },
  },
  methods: {
    markLoading(loading = true) {
      const firestoreID = this.selectedOrder?.firestoreID
      if (firestoreID) this.$emit('markLoading', { firestoreID, loading })
    },
    printReceipt() {
      const order = this.selectedOrder
      if (!order) return
      this.printButtonPressed = true

      if (this.$accessor.printer.printerStatus === 'disconnected') {
        this.$router.push('/imprimante')
      } else sendToPrinter(order)

      setTimeout(() => {
        this.printButtonPressed = false
      }, 200)
    },
    async acknowledgedCanceledOrder() {
      const kitchenID = this.$accessor.settings.lastSelectedKitchen
      const firestoreID = this.selectedOrder?.firestoreID
      if (!firestoreID || !kitchenID) return
      this.markLoading()
      try {
        await ordersAPI.acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
          firestoreID,
          kitchenID
        )
        this.$emit('changeSelectedOrderAfterCanceledAck')
      } catch {
        this.markLoading(false)
      }
    },
    async markReady() {
      const kitchenID = this.$accessor.settings.lastSelectedKitchen
      const firestoreID = this.selectedOrder?.firestoreID
      if (!firestoreID || !kitchenID) return
      this.markLoading()
      try {
        await updatePreparationState(
          firestoreID,
          kitchenID,
          DevorOrderPreparationState.ReadyForCollection
        )
      } catch {
        this.markLoading(false)
      }
    },
  },
})
