

















import Vue, { PropType } from 'vue'

import Zondicon from 'vue-zondicons'

export default Vue.extend({
  name: 'Modal',
  components: {
    Zondicon,
  },
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    contentWidth: {
      type: String as PropType<null | string>,
      default: null,
    },
  },
  computed: {
    style(): string {
      if (!this.contentWidth) {
        return ''
      }

      return `width: ${this.contentWidth}`
    },
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
  },
})
