import { render, staticRenderFns } from "./DisplaySetup.vue?vue&type=template&id=244e8964&scoped=true&"
import script from "./DisplaySetup.vue?vue&type=script&lang=ts&"
export * from "./DisplaySetup.vue?vue&type=script&lang=ts&"
import style0 from "./DisplaySetup.vue?vue&type=style&index=0&id=244e8964&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "244e8964",
  null
  
)

export default component.exports