























import Vue from 'vue'

import Zondicon from 'vue-zondicons'

import { moneyFormatted } from '@/utils/formatting-routines'
import { FirestoreDevorOrder } from '@/interfaces/firestore'
import { DevorMoney } from '@/api-client'

export default Vue.extend({
  components: { Zondicon },
  data: () => ({
    dailySalesTotal: null as null | DevorMoney,
    firebaseUnsubscribe: null as null | (() => void),
  }),
  computed: {
    isLoading(): boolean {
      return this.dailySalesTotal === null
    },
    dailySalesTotalFormatted(): string {
      const { dailySalesTotal } = this
      if (dailySalesTotal === null) return ''
      return moneyFormatted(dailySalesTotal)
    },
  },
  mounted() {
    const kitchenID = this.$accessor.settings.lastSelectedKitchen
    if (!kitchenID) {
      this.$sentry.captureMessage(
        'Assertion failure: `lastSelectedKitchen` is `null`'
      )
      this.$accessor.auth.resetUser()
      this.$accessor.settings.resetKitchenSelection()
      this.$router.push('/login') // Force redirect to the log-in page
      return
    }

    const dateTodayMidnight = new Date()
    dateTodayMidnight.setHours(0, 0, 0, 0)

    this.firebaseUnsubscribe = this.$firebase
      .firestore()
      .collection(`kitchens/${kitchenID}/orders`)
      .where('placed_at', '>=', dateTodayMidnight)
      .onSnapshot((querySnapshot) => {
        let amountTotal = 0.0
        let currency = 'eur'
        querySnapshot.forEach((doc) => {
          const order = doc.data() as Omit<FirestoreDevorOrder, 'firestoreID'>
          if (order.current_state.acceptation === 'canceled')
            // Skip canceled orders
            // (Adding this constraint would require creating Composite Indexes in Firestore)
            return
          order.current_state.acceptation
          amountTotal += order.total_price.amount
          if (order.total_price.currency_code)
            // Note: currency will be the last added value
            currency = order.total_price.currency_code
        })
        this.dailySalesTotal = { amount: amountTotal, currency_code: currency }
      })
  },
})
