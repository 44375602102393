

































































































import Vue from 'vue'
import {
  connectPrinter,
  matchesIPv4Address,
  sendTestTicket,
} from '@/utils/epos-printing'
import { formatPrinterStatus } from '@/utils/formatting-routines'

export default Vue.extend({
  computed: {
    printerBusy(): boolean {
      return this.$accessor.printer.busy
    },
    printerPort: {
      get(): string {
        return this.$accessor.settings.printerPort
      },
      set(value: string) {
        this.$accessor.settings.setPrinterPort(value)
      },
    },
    printerIP: {
      get(): string {
        return this.$accessor.settings.printerIP ?? ''
      },
      set(value: string) {
        this.$accessor.settings.setPrinterIP(value)
      },
    },
    printerHTTPSURL(): string | null {
      if (!matchesIPv4Address(this.printerIP)) {
        // `printerIP` does not appear to be a valid IPv4
        return null
      }
      return 'https://' + this.printerIP
    },
  },
  methods: {
    formatPrinterStatus,
    reConnect() {
      const address = this.$accessor.settings.printerIP
      const port = this.$accessor.settings.printerPort
      if (!address) return
      // print test ticket
      if (this.$ePos.device.isConnected()) {
        this.$ePos.device.disconnect()
      }
      connectPrinter({ address, port }, this.$ePos)
    },
    async printTestTicket() {
      if (this.printerBusy) return
      await sendTestTicket()
    },
    // Note: used by vue-closable in handler
    // eslint-disable-next-line vue/no-unused-properties
    blurPrinterIPOnOutsideClick() {
      if (this.$refs.printerIP) {
        ;(this.$refs.printerIP as HTMLInputElement).blur()
      }
    },
    // Note: used by vue-closable in handler
    // eslint-disable-next-line vue/no-unused-properties
    blurPortNumberOnOutsideClick() {
      if (this.$refs.portNumber) {
        ;(this.$refs.portNumber as HTMLInputElement).blur()
      }
    },
  },
})
