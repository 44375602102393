














































































import Vue from 'vue'
import OrderList from '@/components/OrderList.vue'
import OrderDetails from '@/components/OrderDetails.vue'
import UngroupedItemTitle from '@/components/UngroupedItemTitle.vue'
import ActionPanel from '@/components/ActionPanel.vue'
import Modal from '@/components/Modal.vue'
import Zondicon from 'vue-zondicons'

import { DevorOrderPreparationState } from '@/api-client/model/devor-order-preparation-state'
import { DisplayedDevorOrder } from '@/interfaces/display'
import { OrdersApiFactory } from '@/api-client/api/orders-api'
import { sendToPrinter } from '@/utils/epos-printing'
import { formatOrder, FormatedOrder } from '@/utils/sort'

import {
  isOrderCanceledNotAck,
  isOrderCanceledAck,
  isOrderInKitchen,
  isOrderReady,
} from '@/utils/order-status-filters'

const ordersAPI = OrdersApiFactory(undefined, '')

export default Vue.extend({
  name: 'Home',
  components: {
    OrderList,
    OrderDetails,
    UngroupedItemTitle,
    ActionPanel,
    Modal,
    Zondicon,
  },
  data: () => ({
    ordersCanceledNotAck: Array<DisplayedDevorOrder>(),
    ordersInKitchen: Array<DisplayedDevorOrder>(),
    ordersReady: Array<DisplayedDevorOrder>(),
    ordersCanceledAck: Array<DisplayedDevorOrder>(),
    idsUnseen: new Set<string>(),
    intervalTimer: null as null | number,
    firebaseUnsubscribe: null as null | (() => void),
    isSoundEnabled: null as null | boolean,
    isSoundModalOpened: false,
    isUngroupModalOpened: false,
  }),
  computed: {
    selectedOrderIDParam(): string | null {
      const { id: orderID } = this.$route.params
      if (!orderID || typeof orderID !== 'string') return null
      return orderID
    },
    selectedOrder(): DisplayedDevorOrder | null {
      if (this.selectedOrderIDParam === null) return null
      return this.order(this.selectedOrderIDParam)
    },
    audioSrc(): string {
      return require('@/assets/sounds/happy-ending-415_3_amp.mp3')
    },
    silentAudioSrc(): string {
      return require('@/assets/sounds/silence.mp3')
    },
    ungroupOrder(): FormatedOrder {
      if (!this.selectedOrder) {
        return {
          itemTitles: [],
          notes: [],
        }
      }
      return formatOrder(this.selectedOrder)
    },
  },
  created() {
    const kitchenID = this.$accessor.settings.lastSelectedKitchen
    if (!kitchenID) {
      this.$sentry.captureMessage(
        'Assertion failure: `lastSelectedKitchen` is `null`'
      )
      this.$accessor.auth.resetUser()
      this.$accessor.settings.resetKitchenSelection()
      this.$router.push('/login') // Force redirect to the log-in page
      return
    }

    // Only fetch orders that are under 9 hours old at the time the component was created
    // (This does not update with time, to prevent orders from disappearing)
    // (Note that orders are still being removed from Firebase after 48 hours)
    const minDate = new Date(new Date().getTime() - 9 * 3_600_000)
    this.$sentry.addBreadcrumb({
      category: 'firebase',
      message: 'Creating order request for kitchen.',
      data: { minDate, kitchenID, timeNow: new Date() },
    })

    this.firebaseUnsubscribe = this.$firebase
      .firestore()
      .collection(`kitchens/${kitchenID}/orders`)
      .where('placed_at', '>=', minDate)
      .onSnapshot((querySnapshot) => {
        // Mark added documents as "unseen"
        const newIDs = new Set<string>()
        querySnapshot.docChanges().forEach((change) => {
          switch (change.type) {
            case 'added':
              this.idsUnseen.add(change.doc.id)
              newIDs.add(change.doc.id)
              break
            case 'modified':
              // In order to play notification sound for new cancellations
              newIDs.add(change.doc.id)
              break
            case 'removed':
              this.idsUnseen.delete(change.doc.id)
              break
            default:
              break // else: do nothing
          }
        })

        // Push all updates to a new array
        // that will overwrite our current orders.
        const allOrders = Array<DisplayedDevorOrder>()
        querySnapshot.forEach((doc) => {
          doc
          allOrders.push({
            firestoreID: doc.id,
            ...doc.data(),
          } as DisplayedDevorOrder)
        })

        // Sort orders and split them
        function cmpOrdersPlacedAt(
          a: DisplayedDevorOrder,
          b: DisplayedDevorOrder
        ): number {
          return b.placed_at.seconds - a.placed_at.seconds
        }

        const ordersCanceledNotAck = allOrders
          .filter(isOrderCanceledNotAck)
          .sort(cmpOrdersPlacedAt)

        this.ordersCanceledNotAck = ordersCanceledNotAck

        const ordersInKitchen = allOrders
          .filter(isOrderInKitchen)
          .sort(cmpOrdersPlacedAt)

        const ordersReady = allOrders
          .filter(isOrderReady)
          .sort(cmpOrdersPlacedAt)

        const addedOrdersForKitchen = ordersInKitchen.filter(
          (order) => !order.acknowledged_at && newIDs.has(order.firestoreID)
        )
        if (addedOrdersForKitchen.length > 0) this.sendSoundNotification()

        const addedOrdersCanceledNotAck = ordersCanceledNotAck.filter((order) =>
          newIDs.has(order.firestoreID)
        )
        if (addedOrdersCanceledNotAck.length > 0) this.sendSoundNotification()

        // Send new orders to printer
        let printPromise = Promise.resolve()
        if (this.$accessor.printer.printerStatus === 'connected')
          addedOrdersForKitchen.forEach((order) => {
            // Chain a print request
            printPromise = printPromise.then(() => sendToPrinter(order))
          })

        const ordersCanceledAck = allOrders
          .filter(isOrderCanceledAck)
          .sort(cmpOrdersPlacedAt)

        this.ordersCanceledAck = ordersCanceledAck

        this.updateOrders(ordersInKitchen, ordersReady)

        if (this.selectedOrderIDParam === null) {
          this.selectNextMostRelevantOrder()
        }
      })
  },
  mounted() {
    this.sendSoundNotification(true)
    this.intervalTimer = setInterval(() => {
      // This method is run every second
      this.updateOrders()
    }, 1_000)
  },
  beforeDestroy() {
    clearInterval(this.intervalTimer ?? undefined)
    if (this.firebaseUnsubscribe) this.firebaseUnsubscribe()
  },
  methods: {
    order(firestoreID: string): DisplayedDevorOrder | null {
      return (
        this.ordersInKitchen
          .concat(this.ordersReady)
          .concat(this.ordersCanceledNotAck)
          .concat(this.ordersCanceledAck)
          .find((order) => order.firestoreID === firestoreID) ?? null
      )
    },
    selectNextMostRelevantOrder() {
      const orderId =
        this.ordersCanceledNotAck
          .concat(this.ordersInKitchen)
          .concat(this.ordersReady)
          .concat(this.ordersCanceledAck)
          .map((order) => order.firestoreID)
          .filter((orderId) => orderId !== this.selectedOrderIDParam)
          .find((x) => x !== undefined) ?? null
      this.changeSelectedOrder(orderId)
    },
    // Vue events handling
    async activateSound() {
      this.isSoundEnabled = true
      await this.sendSoundNotification()
    },
    closeSoundModal() {
      this.activateSound() // We activate sound even if they close the Modal
      this.isSoundModalOpened = false
    },
    changeSelectedOrder(newSelectedOrderID: null | string) {
      if (newSelectedOrderID) {
        this.idsUnseen.delete(newSelectedOrderID)
        const selectedOrder = this.order(newSelectedOrderID)
        if (selectedOrder) selectedOrder.isSeen = true
      }
      if (newSelectedOrderID === this.selectedOrderIDParam) return // ID is the same. Do nothing
      if (newSelectedOrderID)
        this.$router.replace({ params: { id: newSelectedOrderID } })
      else this.$router.replace({ params: {} })
    },
    markLoading({
      firestoreID,
      loading,
    }: {
      firestoreID: string
      loading: boolean
    }) {
      const order = this.order(firestoreID)
      if (order) order.isLoading = loading
    },
    // Display methods
    computeDisplayAttributes(
      order: DisplayedDevorOrder,
      dateNow: Date
    ): DisplayedDevorOrder {
      const timeElapsedSinceAcknowledgment = order.acknowledged_at
        ? (dateNow.getTime() - order.acknowledged_at.toMillis()) / 1_000
        : undefined
      return {
        ...order,
        timeElapsedSinceAcknowledgment,
        isSeen: !this.idsUnseen.has(order.firestoreID),
      }
    },
    updateOrders(
      newOrdersInKitchen?: Array<DisplayedDevorOrder>,
      newOrdersReady?: Array<DisplayedDevorOrder>
    ) {
      newOrdersInKitchen = newOrdersInKitchen ?? this.ordersInKitchen
      newOrdersReady = newOrdersReady ?? this.ordersReady

      // Acknowledge new orders (without waiting)
      this.acknowledgeOrders(newOrdersInKitchen.concat(newOrdersReady))

      const dateNow = new Date()
      this.ordersInKitchen = newOrdersInKitchen.map((order) =>
        this.computeDisplayAttributes(order, dateNow)
      )
      // Mark ready all orders that are too old (without waiting)
      this.markReadyAuto(this.ordersInKitchen)
      this.ordersReady = newOrdersReady.map((order) =>
        this.computeDisplayAttributes(order, dateNow)
      )
    },
    async acknowledgeOrders(orders: Array<DisplayedDevorOrder>) {
      const kitchenID = this.$accessor.settings.lastSelectedKitchen
      if (!kitchenID) return
      const ordersToAcknowledge = orders
        .filter((order) => !order.acknowledged_at)
        .map((order) => order.firestoreID)
      if (ordersToAcknowledge.length == 0) return
      await ordersAPI.acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
        kitchenID,
        ordersToAcknowledge
      )
    },
    async markReadyAuto(orders: Array<DisplayedDevorOrder>) {
      const kitchenID = this.$accessor.settings.lastSelectedKitchen
      if (!kitchenID) return
      const acknowledgmentTime =
        this.$accessor.settings.acknowledgementTimeSeconds
      const ordersToMarkReady = orders
        .filter(
          (order) =>
            order.timeElapsedSinceAcknowledgment &&
            order.timeElapsedSinceAcknowledgment > acknowledgmentTime
        )
        .map((order) => order.firestoreID)
      for (const orderID of ordersToMarkReady) {
        // This for loop awaits on each API call to avoid DoS-ing the backend.
        // Most of the time, this loop will only have one order to update.
        await ordersAPI.updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
          orderID,
          kitchenID,
          DevorOrderPreparationState.ReadyForCollection
        )
      }
    },

    async sendSoundNotification(silent = false) {
      if (this.isSoundEnabled === false) return

      const audioElement = !silent
        ? this.$refs.notifSound
        : this.$refs.silentSound
      if (!(!!audioElement && audioElement instanceof HTMLAudioElement)) {
        this.$sentry.captureMessage(
          'Unable to play audio: no audio element found on page.'
        )
        return
      }
      try {
        this.$sentry.addBreadcrumb({
          category: 'sound',
          message: 'Attempting to play sound on audio element...',
        })
        await audioElement.play()
        this.isSoundEnabled = true
        this.isSoundModalOpened = false
        this.$sentry.addBreadcrumb({
          category: 'sound',
          message: 'Successfully played sound on audio element.',
        })
      } catch (error) {
        this.$sentry.addBreadcrumb({
          category: 'sound',
          message: 'Unable to play sound on audio element.',
          data: { error },
        })
        this.isSoundEnabled = null
        this.isSoundModalOpened = true
      }
    },
  },
})
