var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-order-list"},[_vm._l(([
      {
        uid: -1,
        name: 'Nouvelles annulations',
        orders: _vm.ordersCanceledNotAck,
      },
      { uid: 0, name: 'Nouvelles commandes', orders: _vm.ordersInKitchen },
      { uid: 1, name: 'Commandes terminées', orders: _vm.ordersReady },
      { uid: 2, name: 'Commandes annulées', orders: _vm.ordersCanceledAck } ]),function(section,section_idx){return [(section.orders.length > 0)?_c('section',{key:("section-" + section_idx),staticClass:"mt-1"},[_c('header',[_vm._v(" "+_vm._s(section.name)+" ")]),_c('div',{staticClass:"order-list",class:{
          'order-list-canceled-not-ack': section.uid == -1,
          'order-list-in-kitchen': section.uid == 0,
          'order-list-prepared': section.uid == 1,
          'order-list-canceled-ack': section.uid == 2,
        }},_vm._l((section.orders),function(order){return _c('div',{key:order.firestoreID,staticClass:"order-summary p-2 justify-between items-center flex relative",class:{
            'order-selected': order.firestoreID == _vm.selectedOrderId,
          },on:{"click":function($event){return _vm.changeSelectedOrder(order.firestoreID)}}},[_c('div',{staticClass:"flex justify-start items-center"},[_c('ProviderLogo',{attrs:{"provider":order.provider,"width":"45px"}}),_c('div',{staticClass:"flex flex-col justify-between pl-5",staticStyle:{"width":"184px"}},[_c('div',{staticClass:"text-2xl leading-tight font-bold default-font"},[_vm._v(" #"+_vm._s(order.display_id)+" ")]),_c('div',{staticClass:"text-xl overflow-hidden whitespace-nowrap overflow-x-auto",staticStyle:{"max-width":"184px"}},[_c('span',[_vm._v(_vm._s(order.restaurant.short_name))])])])],1),(section.uid == 0)?[(
                !order.isSeen &&
                (order.timeElapsedSinceAcknowledgment || 0) <= 10
              )?_c('div',{staticClass:"badge text-gray-300 bg-green-500 font-bold glow default-font mr-4"},[_vm._v(" NEW ")]):(
                order.timeElapsedSinceAcknowledgment &&
                order.timeElapsedSinceAcknowledgment <= _vm.acknowledgmentTime &&
                // TODO: Change the upper bound below to choose
                // when the timer should be displayed:
                _vm.acknowledgmentTime - order.timeElapsedSinceAcknowledgment <=
                  180
              )?_c('div',{staticClass:"badge text-yellow-500 bg-gray-700 font-bold default-font mr-4"},[_vm._v(" "+_vm._s(_vm.secondsIntervalFormatted( _vm.acknowledgmentTime - order.timeElapsedSinceAcknowledgment ))+" ")]):_vm._e()]:_vm._e(),_c('div',{staticClass:"flex flex-col items-start",staticStyle:{"width":"90px"}},[_c('div',{staticClass:"badge text-white bg-blue-500 font-bold mr-3 mb-2"},[_vm._v(" "+_vm._s(_vm.timeFormatted(order.placed_at))+" ")]),_c('div',{staticClass:"badge price-tag text-white bg-gray-700 font-bold mr-3 mb-2"},[_vm._v(" "+_vm._s(_vm.moneyFormatted(order.total_price))+" ")])])],2)}),0)]):_vm._e()]}),_c('footer',[(_vm.ordersInKitchen.length + _vm.ordersReady.length > 0)?_c('span',[_vm._v("Fin des commandes.")]):_c('span',[_vm._v("Aucune commande.")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }