



































import Vue from 'vue'

export default Vue.extend({
  computed: {
    timerDurationSeconds(): number {
      return this.$accessor.settings.acknowledgementTimeSeconds
    },
    timerDurationMinutes: {
      get(): number {
        return this.timerDurationSeconds / 60
      },
      set(newValue: number) {
        this.$accessor.settings.setAcknowledgementTimeSeconds(
          Math.round(newValue * 60)
        )
      },
    },
  },
  created() {
    this.$accessor.cache.updateKitchens()
  },
})
