import { DevorOrderAcceptationState } from '@/api-client/model/devor-order-acceptation-state'
import { DevorOrderPreparationState } from '@/api-client/model/devor-order-preparation-state'

import { FirestoreDevorOrder } from '@/interfaces/firestore'

export function isOrderCanceledNotAck(
  order: FirestoreDevorOrder | null
): boolean {
  if (!order) {
    return false
  }
  return (
    order.current_state.acceptation == DevorOrderAcceptationState.Canceled &&
    order.acknowledged_at == undefined
  )
}

export function isOrderCanceledAck(order: FirestoreDevorOrder | null): boolean {
  if (!order) {
    return false
  }
  return (
    order.current_state.acceptation == DevorOrderAcceptationState.Canceled &&
    order.acknowledged_at != undefined
  )
}

export function isOrderCanceled(order: FirestoreDevorOrder | null): boolean {
  if (!order) {
    return false
  }
  return order.current_state.acceptation == DevorOrderAcceptationState.Canceled
}

export function isOrderInKitchen(order: FirestoreDevorOrder | null): boolean {
  if (!order) {
    return false
  }
  return (
    order.current_state.acceptation != DevorOrderAcceptationState.Canceled &&
    order.current_state.preparation == DevorOrderPreparationState.InKitchen
  )
}

export function isOrderReady(order: FirestoreDevorOrder | null): boolean {
  if (!order) {
    return false
  }
  return (
    order.current_state.acceptation != DevorOrderAcceptationState.Canceled &&
    order.current_state.preparation != DevorOrderPreparationState.InKitchen
  )
}
