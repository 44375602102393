


















import { ItemTitle } from '@/utils/sort'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'UngroupedItemTitle',
  props: {
    itemTitle: {
      type: Object as PropType<ItemTitle>,
      required: true,
    },
  },
})
