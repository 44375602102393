




import Vue, { PropType } from 'vue'
import { ProviderType } from '@/api-client/model/provider-type'

export default Vue.extend({
  name: 'ProviderLogo',
  props: {
    provider: {
      type: String as PropType<ProviderType | null>,
      default: null,
    },
  },
  computed: {
    src() {
      switch (this.provider) {
        case ProviderType.UberEats:
          return require('@/assets/images/UberEats_Logo.svg')
        case ProviderType.Deliveroo:
        default:
          return require('@/assets/images/Deliveroo_Logo.svg')
      }
    },
  },
})
