















































































































import Vue, { PropType } from 'vue'
import {
  moneyFormatted,
  secondsIntervalFormatted,
  timeFormatted,
} from '@/utils/formatting-routines'
import ProviderLogo from '@/components/ProviderLogo.vue'
import { DisplayedDevorOrder } from '@/interfaces/display'

export default Vue.extend({
  name: 'OrderList',
  components: {
    ProviderLogo,
  },
  props: {
    ordersCanceledNotAck: {
      type: Array as PropType<Array<DisplayedDevorOrder>>,
      default: () => [],
    },
    ordersInKitchen: {
      type: Array as PropType<Array<DisplayedDevorOrder>>,
      default: () => [],
    },
    ordersReady: {
      type: Array as PropType<Array<DisplayedDevorOrder>>,
      default: () => [],
    },
    selectedOrderId: {
      type: String as PropType<null | string>,
      default: null,
    },
    ordersCanceledAck: {
      type: Array as PropType<Array<DisplayedDevorOrder>>,
      default: () => [],
    },
  },
  computed: {
    acknowledgmentTime() {
      return this.$accessor.settings.acknowledgementTimeSeconds
    },
  },
  methods: {
    timeFormatted,
    moneyFormatted,
    changeSelectedOrder(newSelectedOrderID: string | null) {
      this.$emit('changeSelectedOrder', newSelectedOrderID)
    },
    secondsIntervalFormatted,
  },
})
