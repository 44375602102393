































































































































































import Vue, { PropType } from 'vue'
import {
  moneyFormatted,
  timeFormatted,
  fulfillmentTypeFormatted,
  formatItemTag,
} from '@/utils/formatting-routines'
import ProviderLogo from '@/components/ProviderLogo.vue'
import { sortOrder, TicketOrder } from '@/utils/sort'
import { FirestoreDevorOrder } from '@/interfaces/firestore'
import {
  isOrderCanceled,
  isOrderReady,
  isOrderInKitchen,
} from '@/utils/order-status-filters'

export default Vue.extend({
  name: 'OrderDetails',
  components: {
    ProviderLogo,
  },
  props: {
    selectedOrder: {
      type: Object as PropType<null | FirestoreDevorOrder>,
      default: null,
    },
  },
  computed: {
    getTitles(): TicketOrder {
      if (!this.selectedOrder) {
        return {
          ticketItemGroups: [],
          notes: [],
        }
      }
      return sortOrder(this.selectedOrder)
    },
    isSelectedOrderReady(): boolean {
      return isOrderReady(this.selectedOrder)
    },
    isSelectedOrderInKitchen(): boolean {
      return isOrderInKitchen(this.selectedOrder)
    },
    isSelectedOrderCanceled(): boolean {
      return isOrderCanceled(this.selectedOrder)
    },
  },
  methods: {
    timeFormatted,
    moneyFormatted,
    fulfillmentTypeFormatted,
    formatItemTag,
  },
})
